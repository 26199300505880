import { useState } from "react";
import { PayloadPagination } from "../types";

const usePagination = (defaultState) => {
  const [pagination, setPagination] = useState<PayloadPagination>(defaultState);

  const onPageChange = (page = 1) => setPagination({ ...pagination, page });
  const onSizeChange = (size = 30) => setPagination({ ...pagination, pageSize: size });
  const onChangePagination = (newPagination) => setPagination({ ...pagination, ...newPagination });

  return {
    onSizeChange,
    onPageChange,
    onChangePagination,
    pagination,
  };
};

export default usePagination;