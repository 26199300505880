import client from "../utils/client";
import { extractResource, throwResource } from "../utils/restApi";
import castAttributesFromModel from "../utils/castAttributesFromDefinition";
import { PayloadPagination, User } from "../types";

type UsersResponse = {
  pagination: PayloadPagination;
  users: User[];
};
const all = async (page = 1, pageSize = 30, search = {}, format = "json") => {
  const response: UsersResponse = await client.get(`/users.${format}`, {
    params: {
      page,
      pageSize,
      ...search
    }
  });
  return response;
};

const show = async (id) => {
  const response = await client.get(`/users/${id}/edit.json`);
  return response;
};

const newUser = async () => {
  const response = await client.get(`/users/new.json`);
  return response;
};

const current = async () => {
  const response: { user: User } = await client.get('/users/current.json');
  return response;
};

const sync = () => {
  const promise = client.get('/users/sync');
  return promise.then(extractResource).catch(throwResource);
};

const deleteUser = async (id) => {
  const response = await client.delete(`/users/${id}`);
  return response;
};

const saveAvatar = async (editingUser) => {
  const response = await client.put(`/users/${editingUser.id}`, castAttributesFromModel({
    id: "String",
    avatar: "File",
    email: "String",
  }, editingUser, "user"));
  return response;
};

const resendInvitations = async (search) => {
  const response = await client.put("/users/send_invitations", { search });

  return response;
};

const save = async (editingUser) => {
  let response;
  if (editingUser.persisted) {
    response = await client.put(`/users/${editingUser.id}`, { user: editingUser });
  } else {
    response = await client.post(`/users/`, { user: editingUser });
  }
  return response;
};

const updatePassword = async (user) => {
  const response = await client.put(`/users/${user.id}/update_password`, { user });

  return response;
};

const importFile = async ({ separator, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("separator", separator);
  const response = await client.post(`/users/import.json`, formData);

  return response;
};

const UserService = {
  sync,
  all,
  show,
  current,
  save,
  newUser,
  deleteUser,
  saveAvatar,
  resendInvitations,
  importFile,
  updatePassword
};

export default UserService;