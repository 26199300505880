import React, { useState } from "react";
import { connect } from "react-redux";
import {
  PageHeader,
  Space,
  Button,
  Popconfirm,
  Card
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ExportFile, Group } from "../../types";
import OngoingSynchro from '../../components/OngoingSynchro';
import { hasRole } from "../../utils/Authorization";
import styles from "./index.module.sass";
import useI18n from "../../utils/useI18n";
import HierarchyTitleList from "./components/HierarchyTitleList";
import { useHierarchyBranches } from "./useHierarchyTitle";
import HierarchyTitleEdit from "./components/HierarchyTitleEdit";
import client from "../../utils/client";
import HierarchyTitleImporter from "./components/HIerarchyTitleImporter";

interface EntityPageProps {
  group: Group;
  addExportFile: (exportFile: ExportFile) => void;
}

const onExport = (addExportFile) => {
  const endpoint = `/hierarchy_titles/export.xlsx`;
  //@ts-ignore
  client.get(endpoint).then(({ exportFile }) => addExportFile(exportFile));
};

const HierarchyTitle: React.FC<EntityPageProps> = props => {
  const {
    group,
    addExportFile,
  } = props;

  const [editVisible, setEditVisible] = useState(false);
  const [importerOpen, setImporterOpen] = useState(false);
  const [hierarchyTitleId, setHierarchyTitleId] = useState<string | undefined>();

  const { t } = useI18n();

  const branches = useHierarchyBranches();
  if (!branches) return null;

  return (
    <>
      <OngoingSynchro />
      <div className={styles.hierarchyTitleWrapper}>
        <PageHeader className={styles.pageHeader} title={t("hierarchy_title.header")} />
        <div className={styles.actionsWrapper} key="actions-wrapper">
          <Space>
            { hasRole(group, ["entity_create"]) && (
              <Button
                key="add-site-button"
                onClick={() => {
                  setEditVisible(true);
                  setHierarchyTitleId("");
                }}
                icon={<PlusOutlined />}
              >
                {t("hierarchy_title.add_hierarchy_title")}
              </Button>
            )}
            { hasRole(group, ["entity_import"]) && (
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => setImporterOpen(true)}
              >
                {t("import")}
              </Button>
            )}
            <Button
              icon={<FileExcelOutlined />}
              onClick={() => onExport(addExportFile)}
            >
              {t("words.export")}
            </Button>
            {hasRole(group, ["synchronize"]) && (
              <Popconfirm
                title={t("synchronization.global_confirm")}
                okText={t("words.ok")}
                onConfirm={() => client.get(`/hierarchy_titles/sync`)}
                cancelText={t("words.cancel")}
                placement="bottomRight"
              >
                <Button
                  icon={<SyncOutlined />}
                >
                  {t("synchronization.global_entity")}
                </Button>
              </Popconfirm>
            )}
          </Space>
        </div>
      </div>
      <Card>
        {hasRole(group, ["entity_import"]) && importerOpen && (
          <HierarchyTitleImporter
            importerOpen={importerOpen}
            onImporterOpen={setImporterOpen}
          />
        )}
        {editVisible && (
          <HierarchyTitleEdit
            hierarchyTitleId={hierarchyTitleId || ""}
            visible={editVisible}
            branches={branches}
            group={group}
            onCancel={() => {
              setEditVisible(false);
              setHierarchyTitleId(undefined);
            }}
          />
        )}
        <HierarchyTitleList
          onEdit={(id) => {
            setHierarchyTitleId(id);
            setEditVisible(true);
          }}
          branches={branches}
          group={group}
        />
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  group: state.group.current,
});

const mapDispatchToProps = dispatch => ({
  addExportFile: (file) => {
    dispatch({ type: 'exportFiles/addToList', payload: file });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyTitle);