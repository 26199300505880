import React, { useState } from 'react';
import AntForm from "@9troisquarts/ant-form.ant-form";
import { Tenant } from '../types';
import {
  Button,
  Card,
 } from 'antd';
import styles from "../Login/index.module.sass"
import { getTenantColorClassName } from '../utils/tenantTheme';
import { FormattedMessage, useIntl } from 'react-intl';
import { post } from '../utils/post';
import TranslationProvider from '../utils/TranslationProvider';
interface IProps {
  tenant: Tenant;
  locale: string;
}

const ForgottenPassword: React.FC<IProps> = props => {
  const {
    tenant,
  } = props;

  const intl = useIntl();

  // set locale and messages for IntlProvider.
  const [login, setLogin] = useState({});

  const onSubmit = () => {
    const data = { ...login };
    post('/users/password', data);
  };

  return (
    <Card className={styles.wrapper}>
      {tenant.logo && (
        <div style={{ textAlign: 'center' }}>
          <img style={{ width: "300px" }} src={tenant.logo} alt={`${tenant.subdomain}_logo`}/>
        </div>
      )}
      <div className={`${styles.loginWrapper} ${getTenantColorClassName(tenant.navigationColor, tenant.primaryColor, tenant.secondaryColor)}`}>
        <div style={{ fontSize: "18px", fontWeight: 700, marginBottom: "14px" }}>
          <FormattedMessage id="devise.sessions.forgot_password" />
        </div>
        { !tenant.provider && (
          <>
            <AntForm
              style={{ width: "400px", fontWeight: 600, fontSize: "12px" }}
              layout="vertical"
              schema={[{
                name: "email",
                label: intl.formatMessage({ id: "activerecord.attributes.user.email" }),
                input: {
                  type: "string",
                }
              }]} 
              object={login}
              onChange={(values) => setLogin({ ...login, ...values})}
            />
            <Button
              onClick={onSubmit}
              type="primary"
            >
              <FormattedMessage id="devise.passwords.send_instructions" />
            </Button>
          </>
        )}
      </div>
    </Card>
  )
};

export default TranslationProvider(ForgottenPassword);