import React from "react";
import { useQuery } from 'react-query';
import { useIntl } from "react-intl";
import { Alert } from "antd";
import client from "../../utils/client";

const getCurrentSynchro = async ({ queryKey: [key] }, format = "json") => {
  const response = await client.get('/tenants/ongoing_jobs');
  return response;
};

const OngoingSynchro = props => {
  const intl = useIntl();
  const { data } = useQuery({
    queryKey: ['ongoing_synchro'],
    queryFn: getCurrentSynchro,
    refetchInterval: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  //@ts-ignore
  const job_count = data?.count;

  return (
    <>
      {(data && job_count !== 0) && (
        <Alert message={intl.formatMessage({ id: "synchronization.pending" })} style={{ width: "100%", marginTop: "10px", textAlign: "center" }} />
      )}
    </>
  );
};

export default OngoingSynchro;
