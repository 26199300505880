// @ts-nocheck
import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  Menu,
} from 'antd';
import {
  MenuFoldOutlined, MenuUnfoldOutlined
} from '@ant-design/icons';
import SVG from 'react-inlinesvg';
import { useIntl } from "react-intl";
import routes from "../utils/routes";
import styles from "./index.module.sass";
import { Tenant, Group } from "../types";
import { hasRole } from "../utils/Authorization";

const { SubMenu } = Menu;

const MenuCollapserIcon = ({ collapsed, ...rest }) => {
  if (collapsed) return (<MenuUnfoldOutlined {...rest} />);
  return (<MenuFoldOutlined {...rest} />);
};
interface IProps {
  tenant: Tenant;
  onSelect: ({ key: string }) => void;
  internalUser: boolean;
  collapsed: boolean;
  group: Group;
  setCollapsed: (collapsed: boolean) => void;
}

const iconForMenu = icon => {
  if (!icon) return null;

  if (icon.src) return (<SVG {...icon} />);

  const Icon = icon;
  return <Icon style={{ fontSize: "20px" }} />;
};

const Navigation: React.FC<IProps> = props => {
  const {
    tenant,
    onSelect,
    collapsed,
    setCollapsed,
    internalUser,
    group,
  } = props;
  const intl = useIntl();
  const [routeKeys, setRoutesKeys] = useState<{selected?: string[], open?: any[]}>({});

  return (
    <div className={styles.menuWrapper} style={{ backgroundColor: tenant.navigationColor, width: collapsed ? "80px" : "250px" }}>
      <div className={styles.collapsedWrapper}>
        <a className={styles.collapser} onClick={() => setCollapsed(!collapsed)}>
          <MenuCollapserIcon
            key="menu-collapser"
            collapsed={collapsed}
            style={{ color: tenant.secondaryColor, fontSize: "20px" }}
          />
        </a>
      </div>
      <Menu
        mode="inline"
        onSelect={onSelect}
        onOpenChange={(value) => {
          setRoutesKeys({ ...routeKeys, open: value });
        }}
        selectedKeys={routeKeys.selected}
        openKeys={routeKeys.open}
      >
        {routes(group, intl).map((menuItem, i) => {
          if (menuItem && (hasRole(group, menuItem.allowedRoles) || menuItem.skipRoles) && (!menuItem.onlyInternal || internalUser)) {
            let item;
            if (menuItem.submenu && menuItem.submenu.length > 0) {
              item = (
                <SubMenu
                  key={menuItem.pathname}
                  style={{ backgroundColor: "#FFFFFF" }}
                  icon={iconForMenu(menuItem.icon)}
                  
                  title={menuItem.title || intl.formatMessage({ id: `navigation.${menuItem.pathname}` })}
                >
                  {
                    menuItem.submenu.map(submenuItem => (
                      <>
                        { (hasRole(group, menuItem.allowedRoles) || menuItem.skipRoles === true) && (!menuItem.onlyInternal || internalUser) && (
                          <Menu.Item icon={iconForMenu(submenuItem.icon)} key={submenuItem.pathname}>
                            {submenuItem.title || intl.formatMessage({ id: `navigation.${submenuItem.pathname}` })}
                          </Menu.Item>
                        )}
                      </>
                    ))
                  }
                </SubMenu>
              );
            } else {
              item = (
                <Menu.Item
                  key={menuItem.pathname || i}
                  icon={iconForMenu(menuItem.icon)}
                >
                  {menuItem.title || intl.formatMessage({ id: `navigation.${menuItem.pathname}` })}
                </Menu.Item>
              );
            }
            return item;
          }
        })}
      </Menu>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onSelect: ({ key }) => {
    if (key.includes("part")) {
      const part = key.split('part=')[1];
      dispatch({
        type: "user/setPart",
        payload: part,
      });
    }
    dispatch(push(`${key}`));
  }
});

export default connect((state, ownProps) => ({...ownProps}), mapDispatchToProps)(Navigation);