import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Popconfirm,
  Button,
  Card,
  PageHeader,
} from "antd";
import { SyncOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  User as UserType,
  ApplicationType,
  HierarchyTree,
  Tenant,
  ExportFile,
  Group,
  PayloadPagination,
} from '../../types';
import styles from './index.module.sass';
import memoOnlyForKeys from '../../utils/memoOnlyForKeys';
import client from '../../utils/client';
import UserList from './components/UserList';
import usePagination from '../../utils/usePagination';
import UserService from '../../services/user';
import UserEdit from './components/UserEdit';
import UserImporter from './components/UserImporter';
import OngoingSynchro from '../../components/OngoingSynchro';
import useI18n from '../../utils/useI18n';
import { hasRole } from '../../utils/Authorization';
import PasswordEdit from './components/PasswordEdit';
import { useCurrentUser } from "./components/UserEdit/utils";

const getAllUser = async ({ queryKey: [key, page, pageSize, nameEmail, applicationId, invitationState, since, unsynced, part] }, format = "json") => {
  const search = {
    nameEmail,
    applicationId,
    invitationState,
    since,
    part,
    unsynced
  };
  const response = await UserService.all(page, pageSize, search, format);
  return response;
};

interface IProps {
  applications: ApplicationType[];
  visible: boolean;
  wizardSteps: string[];
  editingUser: UserType;
  editParams: any;
  editLoading: boolean;
  hierarchyTrees: HierarchyTree[];
  errors: { [key: string]: string[] };
  saveLoading: boolean;
  importerOpen: boolean;
  tenant: Tenant;
  importErrors: { email: string; messages: string; application: string }[];
  part: string;
  group: Group;
  passwordVisible: boolean;
  onEditCancel: () => void;
  onResendInvitations: (search) => void;
  onSubmit: (editingUser: UserType, options: any) => void;
  addExportFile: (file: ExportFile) => void;
  onPasswordSave: (editingUser: UserType) => void;
  onDelete: (id: string) => void;
  onImporterOpen: (open: boolean) => void;
  onImport: (file: any) => void;
  onPasswordEdit: (id: string) => void;
  onUserSynchronization: () => void;
  onEdit: (id?: string) => void;
  fetchApplications: () => void;
  fetchHierarchyTrees: () => void;
}

const onExport = (search, addExportFile, part) => {
  let queryString = `part=${part}&`;
  if (search.invitationState) {
    queryString += `invitation_state=${search.invitationState}&`;
  }
  if (search.since) {
    queryString += `since=${search.since}&`;
  }
  if (search.nameEmail) {
    queryString += `name_email=${search.nameEmail}&`;
  }
  if (search.applicationId) {
    queryString += `application_id=${search.applicationId}&`;
  }
  const endpoint = `/users/export.xlsx?${queryString}`;
  //@ts-ignore
  client.get(endpoint).then(({ exportFile }) => addExportFile(exportFile));
};

type UserSearch = {
  nameEmail?: string;
  applicationId?: string;
  invitationState?: string;
  since?: string;
  part?: string;
  unsynced?: boolean;
};

const User: React.FC<IProps> = props => {
  const {
    onUserSynchronization,
    applications,
    fetchApplications,
    onEdit,
    visible,
    editingUser,
    wizardSteps,
    onEditCancel,
    editParams,
    editLoading,
    hierarchyTrees,
    fetchHierarchyTrees,
    errors,
    onDelete,
    saveLoading,
    onResendInvitations,
    onImporterOpen,
    importerOpen,
    onImport,
    tenant,
    addExportFile,
    importErrors,
    part,
    group,
    onPasswordEdit,
    passwordVisible,
    onPasswordSave,
  } = props;

  useEffect(() => {
    fetchApplications();
    fetchHierarchyTrees();
  }, []);

  const { t } = useI18n();
  const {
    onChangePagination,
    pagination,
  } = usePagination({ page: 1, pageSize: 30 });

  const [search, setSearch] = useState<UserSearch>({});

  const {
    isLoading: loading,
    data,
    refetch
  } = useQuery({
    queryKey: ['users', pagination.page, pagination.pageSize, search.nameEmail, search.applicationId, search.invitationState, search.since, search.unsynced, part],
    queryFn: getAllUser,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: ({ pagination }) => onChangePagination(pagination)
  });

  const onSubmit = (values: UserType)  => props.onSubmit(values, { onCompleted: refetch })

  const currentUser = useCurrentUser();
  if (!currentUser) return null;

  const editingRoles = ["user_qualimetrie", "user_network_manager"];
  applications.forEach(app => editingRoles.push(`user_application_${app.id}`));

  return (
    <>
      <OngoingSynchro />
      <div
        key="user-search-form"
        className={styles.userWrapper}
      >
        {hasRole(group, ["user_import"]) && importerOpen && (
          <UserImporter
            importerOpen={importerOpen}
            loading={editLoading}
            onImporterOpen={onImporterOpen}
            importErrors={importErrors}
            onImport={onImport}
          />
        )}
        <PageHeader className={styles.pageHeader} title={t("user.header")} />
        <div className={styles.synchronizeWrapper}>
          {hasRole(group, editingRoles) && (
            <Button
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              onClick={() => onEdit()}
              type="primary"
            >
              {t("user.new")}
            </Button>
          )}
          {hasRole(group, ["user_import"]) && (
            <Button
              icon={<FileExcelOutlined />}
              style={{ marginRight: "10px" }}
              onClick={() => onImporterOpen(!importerOpen)}
              type="primary"
            >
              {t("user.import")}
            </Button>
          )}
          <Button
            icon={<FileExcelOutlined />}
            style={{ marginRight: "10px" }}
            onClick={() => onExport(search, addExportFile, part)}
          >
            {t("user.export")}
          </Button>
          {hasRole(group, ["synchronize"]) && (
            <Popconfirm
              title={<div style={{ width: "450px" }}>{t("user.sync")}</div>}
              onConfirm={() => onUserSynchronization()}
              okText={t("words.ok")}
              cancelText={t("words.cancel")}
              placement="bottomRight"
            >
              <Button
                icon={<SyncOutlined />}
              >
                {t("synchronization.user")}
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
      <Card style={{ position: "relative" }} className={styles.siteListWrapper}>
        <UserList
          key="user-list"
          loading={loading}
          users={data?.users || []}
          setSearch={(value) => setSearch(value)}
          pagination={pagination}
          search={search}
          onEdit={onEdit}
          applications={applications}
          setPagination={onChangePagination}
          currentUser={currentUser}
          group={group}
          onResendInvitations={onResendInvitations}
          editingRoles={editingRoles}
          onPasswordEdit={onPasswordEdit}
          part={part}
        />
      </Card>
      {hasRole(group, editingRoles) && visible && (
        <UserEdit
          key="user-editing-modal"
          hierarchyTrees={hierarchyTrees}
          editLoading={editLoading}
          visible={visible}
          onCancel={onEditCancel}
          user={editingUser}
          group={group}
          wizardSteps={wizardSteps}
          applications={applications}
          editParams={editParams}
          onSubmit={onSubmit}
          errors={errors}
          onEdit={onEdit}
          currentUser={currentUser}
          onDelete={onDelete}
          saveLoading={saveLoading}
          tenantLocales={tenant.availableLocales}
          authProviders={tenant.authProviders}
          hasApiKey={tenant.hasApiKey}
        />
      )}
      {hasRole(group, ["user_update_password"]) && passwordVisible && (
        <PasswordEdit
          user={editingUser}
          onPasswordSave={onPasswordSave}
          onCancel={onEditCancel}
          passwordVisible={passwordVisible}
          editLoading={editLoading}
          saveLoading={saveLoading}
          errors={errors}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  users: state.users.list,
  pagination: state.users.pagination,
  visible: state.users.open,
  editingUser: state.users.current,
  errors: state.users.errors,
  wizardSteps: state.users.wizardSteps,
  applications: state.applications.list,
  editParams: state.users.editParams,
  editLoading: state.users.loading,
  hierarchyTrees: state.hierarchyTrees.list,
  saveLoading: state.users.saveLoading,
  importerOpen: state.users.importerOpen,
  tenant: state.tenants.current,
  importErrors: state.users.importErrors,
  part: state.users.part,
  passwordVisible: state.users.passwordVisible,
  group: state.group.current,
});

const mapDispatchToProps = dispatch => ({
  onEditCancel: () => dispatch({ type: "user/cancelEdit" }),
  onUserSynchronization: () => dispatch({ type: "user/sync" }),
  fetchApplications: () => dispatch({ type: "application/fetch" }),
  fetchHierarchyTrees: () => dispatch({ type: "hierarchyTree/fetch" }),
  onEdit: (id?: string) => dispatch({ type: "user/show", payload: { id } }),
  onPasswordEdit: (id?: string) => dispatch({ type: "user/editPassword", payload: { id } }),
  onSubmit: (editingUser, { onCompleted } = { onCompleted: undefined }) => {
    dispatch({
      type: "user/save",
      payload: editingUser, 
      options: {
        onCompleted
      },
    });
  },
  onPasswordSave: (editingUser) => dispatch({ type: "user/updatePassword", payload: editingUser }),
  onDelete: (id) => {
    dispatch({ type: "user/delete", payload: id });
  },
  onResendInvitations: (search) => {
    dispatch({ type: "user/resendInvitations", payload: search });
  },
  onImporterOpen: (open) => {
    dispatch({ type: "user/setImporterOpen", payload: open });
  },
  onImport: (file) => {
    dispatch({ type: 'user/importUsers', payload: file });
  },
  addExportFile: (file) => {
    dispatch({ type: 'exportFiles/addToList', payload: file });
  }
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(User), memoOnlyForKeys(["users", "editLoading", "visible", 'importerOpen', 'passwordVisible']));