import {
  CheckCircleOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Alert,
  Collapse,
  Modal,
  Space
} from 'antd';
import React from 'react';

type SynchroStateHelpProps = {
  open?: boolean;
  onCancel?: () => void;
}

const SynchroStateHelp: React.FC<SynchroStateHelpProps> = ({ open, onCancel }) => {
  if (!open) return null;

  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      footer={null}
      width={650}
      title="Etat de synchronisation"
    >
      <Space direction="vertical" size={16}>
        <div>
          <strong><CheckCircleOutlined style={{ color: "green", marginRight: "5px", fontSize: "20px" }} /></strong>&nbsp;:&nbsp;L'utilisateur est synchronisé.
        </div>
        <div>
          <WarningOutlined style={{ fontSize: "20px", color: "orange" }} />&nbsp;:&nbsp;L'utilisateur n'est pas synchronisé sur un ou plusieurs portails.
        </div>
        <div>
          <WarningOutlined style={{ fontSize: "20px", color: "red" }} />&nbsp;:&nbsp;L'utilisateur n'est pas synchronisé.
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <QuestionCircleOutlined style={{ fontSize: "20px", color: "orange" }} />&nbsp;:&nbsp;
          <span>
            Le statut de synchronisation de l'utilisateur n'est pas connu, ce statut s'affiche généralement après qu'un utilisateur ait été créé ou mis à jour. <br /> <small><i><InfoCircleOutlined /> L'état de synchronisation des utilisateurs dans cet état est mis à jour toutes les 30 minutes</i></small>
          </span>
        </div>
        <div>
          <Alert
            type="info"
            description={(
              <div>
                <strong>Un ou plusieurs utilisateurs ne sont pas synchronisés :</strong>
                <br />
                Pour forcer une synchronisation de l'utilisateur sur un portail, ouvrez la fiche de l'utilisateur et cliquez sur le bouton <strong>Enregistrer</strong>, cela passera son statut en non défini <QuestionCircleOutlined style={{ color: "orange" }} />.
                <br />
                Si après plus de 30 minutes, l'utilisateur est passé de l'état <i>non défini</i> ( <QuestionCircleOutlined style={{ color: "orange" }} /> ) à un état <i>non synchronisé</i> (<WarningOutlined style={{ color: "orange" }} /> / <WarningOutlined style={{ color: "red" }} />), cela signifie que votre utilisateur est en erreur, dans cas, veuillez consulter l'équipe technique.
              </div>
            )}
          />
        </div>
      </Space>
    </Modal>
  )
}

export default SynchroStateHelp;